<template>
  <b-modal
    :id="idModalCreate"
    size="sm"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreateStruct"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-form-group>
          <label for="InputHelp">Mã cơ cấu tổ chức <span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="code"
            rules="required"
            :custom-messages="customCode"
          >
            <b-form-input
              id="InputHelp"
              v-model="queryBody.code"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="InputHelp">Tên cơ cấu tổ chức <span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="customMessages"
          >
            <b-form-input
              id="InputHelp"
              v-model="queryBody.name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <b-form-group>
      <label for="InputHelp">Cơ cấu tổ chức cha</label>
      <organization-select
        v-model="queryBody.parentId"
        :parentChildrenId="parentChildrenId"
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Mô tả</label>
      <b-form-textarea
        id="InputHelp"
        v-model="queryBody.description"
        rows="3"
        no-resize
      />
    </b-form-group>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import OrganizationSelect from '../../../users/pages/components/OrganizationSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    OrganizationSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
  },
  props: {
    idModalCreate: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataOrganizationStructId: {
      type: Object,
      default: _ => {},
    },
    parentOfParentId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      queryBody: {
        code: '',
        name: '',
        parentId: '',
        description: '',
      },
      parentChildrenId: '',
      dataDetailStrcut: null,
      customCode: {
        required: 'Mã cơ cấu tổ chức là bắt buộc',
      },
      customMessages: {
        required: 'Tên cơ cấu tổ chức là bắt buộc',
      },
    }
  },
  watch: {
    dataOrganizationStructId: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            code: this.dataOrganizationStructId.code,
            name: this.dataOrganizationStructId.name,
            parentId: this.dataOrganizationStructId.parentId,
            description: this.dataOrganizationStructId.description,
          }
        }
        if (val && this.type === 'add') {
          this.queryBody = {
            code: '',
            name: '',
            parentId: this.dataOrganizationStructId.id,
            description: '',
          }
        }
      },
    },
  },
  methods: {
    handleCreateStruct(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('handleCreateStruct', this.queryBody, this.$refs.rule)
    },
    resetState() {
      this.queryBody = {
        code: '',
        name: '',
        parentId: '',
        description: '',
      }
    },
  },
}
</script>

<style>

</style>
