<template>
  <div>
    <b-modal
      :id="id"
      :hide-footer="true"
      centered
      :hide-header="false"
      :no-close-on-backdrop="isNoClose"
      title="Thêm vị trí công việc"
      @hidden="close"
    >
      <div class="form-input">
        <validation-observer ref="rule">
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputCode"
              >Mã vị trí công việc <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="{required: 'Mã vị trí công việc là bắt buộc'}"
              >
                <b-form-input
                  id="basicInputCode"
                  v-model=" dataValue.code "
                  :state="errors.length > 0 ? false:null"
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputName"
              >Tên vị trí công việc <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="{required: 'Tên vị trí công việc là bắt buộc',}"
              >
                <b-form-input
                  id="basicInputName"
                  v-model=" dataValue.name "
                  :state="errors.length > 0 ? false:null"
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <b-form-group>
            <label
              for="basicInput"
            >Nhóm công việc <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: 'Nhóm công việc là bắt buộc',}"
            >
              <v-select
                v-model="dataValue.positionGroup"
                :reduce="title => title.name"
                label="title"
                :options="allPosition"
                :placeholder="'Lựa chọn nhóm công việc'"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>

            <div>
              <label for="textarea-default">Cơ cấu tổ chức</label>
              <b-form-input
                id="textarea-default"
                v-model="nameOrStru"
                placeholder=""
                rows="3"
                disabled
                no-resize
              />
            </div>
          </b-form-group>
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputName"
              >Yêu cầu công việc </label>
              <b-form-input
                id="basicInputName"
                v-model=" dataValue.requirement "
                value=""
              />
            </b-form-group>
          </div>
          <div>
            <label for="textarea-default">Mô tả công việc</label>
            <b-form-textarea
              id="textarea-default"
              v-model="dataValue.description"
              placeholder=""
              rows="3"
              no-resize
            />
          </div>
        </validation-observer>
      </div>
      <div class="action">
        <b-button
          v-if="!isNotification"
          variant="outline-primary mr-1 "
          @click="cancel"
        >
          Hủy bỏ
        </b-button>
        <b-button
          variant="primary"
          class="btn-left"
          @click="accept"
        >
          Lưu lại
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCardText, BButton, BFormGroup, BForm, BCol, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import OrganizationSelect from '@/views/management-user/users/pages/components/OrganizationSelect.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    BCardText,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BForm,
    BCol,
    BFormInput,
    BFormTextarea,
    ToastificationContent,
    OrganizationSelect,
  },
  props: {

    nameOrStru: {
      type: String,
      default: '',
    },

    idDetailOrganition: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    id: {
      type: String,
      default: 'confirm-modal',
    },
    // nếu là thông báo không có nút cancel
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: true,
    },
    idDetail: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      allPosition: [
        {
          name: 'Another',
          title: 'Khác',
        },
        {
          name: 'Manage',
          title: 'Nhà quản lý',
        },
        {
          name: 'HighLevel',
          title: 'Chuyên mô kỹ thuật bật cao',
        },
        {
          name: 'MidLevel',
          title: 'Chuyên môn kỹ thuật bật trung',
        },
      ],
      dataValue: {
        code: '',
        name: '',
        description: '',
        requirement: '',
        positionGroup: '',
        organizationalStructureId: '',
      },
      checkCodeDT: '',
      checkNameDT: '',
      disableProp: false,
      modalTitle: 'Thêm vị trí công việc',
    }
  },

  watch: {
    idDetailOrganition(val) {
      if (val) {
        this.dataValue.organizationalStructureId = this.idDetailOrganition
      }
    },
  },
  methods: {

    //* **Kiểm tra mã trùng** */
    async checkCode() {
      const res = await axiosApiInstance.get('/Position/existCode', { params: { code: this.dataValue.code, id: this.idDetail } })
      if (res.status === 200) {
        this.checkCodeDT = res.data
        if (res.data === true) {
          this.$root.$bvToast.toast('Mã bị trùng', {
            title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
          })
        }
      }
    },
    //* **Kiểm tra tên trùng** */
    async checkName() {
      const res = await axiosApiInstance.get('/Position/existName', { params: { name: this.dataValue.name, id: this.idDetail } })
      if (res.status === 200) {
        this.checkNameDT = res.data
        if (res.data === true) {
          this.$root.$bvToast.toast('Tên bị trùng', {
            title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
          })
        }
      }
    },
    //* **phần thêm** */
    async add() {
      try {
        this.$showAllPageLoading()
        const resuft = await axiosApiInstance.post('/Position/positions', this.dataValue)
        if (resuft.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } catch (e) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm không thành công!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    async edit() {
      try {
        this.$showAllPageLoading()
        const res = await axiosApiInstance.put('Position/positions', this.dataValue)
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Sửa thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } catch (e) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Sửa không thành công!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    async accept() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          await this.checkName()
          await this.checkCode()
          if (this.checkNameDT === false && this.checkCodeDT === false) {
            if (this.idDetail) {
              this.dataValue.id = this.idDetail
              await this.edit()
              this.$bvModal.hide(this.id)
              this.$emit('callAPI')
            } else {
              await this.add()
              this.$bvModal.hide(this.id)
              this.$emit('callAPI')
            }
          }
        }
      })
    },
    close() {
      this.dataValue = {}
    },
    cancel() {
      this.dataValue = {
        code: '',
        name: '',
        description: '',
      }
      if (this.$route.params.id) {
        this.$router.push({ name: 'position-job' })
      }
      this.$bvModal.hide(this.id)
      this.$emit('cancel')
    },
  },
}
</script>
<style scoped>
.action{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0px 20px 20px;
  margin-top: 20px;
  border-top: 1px solid #CBCDD2 ;
}
.border-organization{
  /* border: 1px solid #A6A8B0 !important; */
  border-radius: 0.357rem;
}
</style>
